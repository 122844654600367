import { useCallback } from 'react';
import { TFeatures, useAppStore } from '../store';

export const useFeature = () => {
  const features = useAppStore(state => state.config.features);
  const hasFeature = useCallback((feature: keyof TFeatures) => features && features[feature] === true, [features]);

  return {
    hasFeature,
  };
};
