import { forwardRef } from 'react';
import { ToggleStyled } from './toggle.styled';
import { IToggleProps } from './toggle.types';

export const Toggle = forwardRef<HTMLInputElement, IToggleProps>(
  ({ children, checked = false, disabled, readOnly, ...props }, ref) => {
    return (
      <ToggleStyled $checked={checked} aria-disabled={disabled || readOnly}>
        <input
          ref={ref}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          readOnly={readOnly}
          {...props}
        />
        {children}
      </ToggleStyled>
    );
  },
);
