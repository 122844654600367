import styled from 'styled-components';

export const DropDownStyled = styled.div`
  .content {
    z-index: 10;
  }

  .drop-shadow {
    z-index: 2;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: ${({ theme }) => theme.colors.gray[80]}0D;
  }
`;
