import { CSSProperties } from 'react';
import { EElementSize } from '../../types';

export enum EState {
  WARNING = 'warning',
  ERROR = 'error',
  NONE = ''
}

export type TSize = EElementSize | 'textarea';

export interface IInputWrapperProps<T = HTMLInputElement> extends React.InputHTMLAttributes<T> {
  children?: React.ReactNode;
  icon?: React.ReactElement;
  actionButtons?: React.ReactElement;
  state?: EState;
  isClearable?: boolean;
  placeholder?: string;
  width?: string;
  $size?: TSize;
  cursor?: CSSProperties["cursor"];
  $isStatic?: boolean;
  onClear?: () => void;
}

export interface IInputWrapperStyledProps {
  $state: EState;
  $size: TSize;
  $cursor?: CSSProperties["cursor"];
  $isStatic?: boolean;
  $width?: string;
  disabled?: boolean;
}
