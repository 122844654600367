import styled from 'styled-components';
import { IMultiSelectFieldStyledProps, IMultiSelectListItemStyledProps } from './multi-select.types';

export const MultiSelectListStyled = styled.div`
  max-height: 360px;
  overflow-y: auto;
`;

export const MultiSelectListItemStyled = styled.div<IMultiSelectListItemStyledProps>`
  background-color: ${({ $isSelected, theme }) => $isSelected ? theme.colors.blue[5] : theme.colors.gray[0]};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: ${({ $isSelected, theme }) => $isSelected ? theme.colors.gray[90] : theme.colors.gray[50]};
  cursor: pointer;

  label {
    padding: 10px 16px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[3]};
    color: ${({ theme }) => theme.colors.gray[70]};
  }
`;

export const MultiSelectFieldStyled = styled.div<IMultiSelectFieldStyledProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
  gap: 8px;
  height: 100%;

  .placeholder {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.gray[30]};
  }

  .chips {
    display: flex;
    gap: 4px;
    overflow-x: hidden;
  }

  .actions {
    display: flex;
    align-items: center;
    word-break: keep-all;
    white-space: nowrap;
    gap: 8px;

    .more-count {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.gray[50]};
      display: flex;
      align-items: center;
      position: relative;
      height: 100%;
      padding-right: 4px;

      &:before {
        content: "";
        position: absolute;
        width: 12px;
        left: -20px;
        top: 0;
        bottom: 0;

        background: linear-gradient(270deg, #F6F7F9 0%, rgba(246, 247, 249, 0.6) 59.9%, rgba(246, 247, 249, 0) 100%);
      }
    }
  }

  .arrow {
    cursor: ${({ $isDisabled }) => $isDisabled ? 'not-allowed' : 'pointer'};

    transform: rotate(${({ $isOpen }) => $isOpen ? 180 : 0}deg);
  }
`;
