import { forwardRef } from 'react';
import { CheckIcon, CheckMinusIcon } from '../icons';
import { CheckboxStyled } from './checkbox.styled';
import { ECheckboxPosition, ICheckboxProps } from './checkbox.types';

export const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  (
    {
      children,
      position = ECheckboxPosition.LEFT,
      checked,
      disabled = false,
      ...props
    },
    ref,
  ) => {
    return (
      <CheckboxStyled
        $checked={checked}
        $position={position}
        $disabled={disabled}
      >
        <div className="icon">
          <CheckIcon className="check" />
          <CheckMinusIcon className="minus" />
        </div>
        <input type="checkbox" checked={!!checked} {...props} ref={ref} />
        {children}
      </CheckboxStyled>
    );
  },
);
