import styled, { RuleSet, css } from 'styled-components';
import { EElementSize } from '../../types';
import { ESelectorTabSize, ISelectorTabItemStyledProps } from './selector-tab.types';

export const SelectorTabStyled = styled.div`
  width: max-content;

  .wrapper {
    padding: 4px;
    width: auto;
    gap: 0;
  }
`;

const ItemSizeMap: Record<ESelectorTabSize, RuleSet> = {
  [EElementSize.L]: css`
    padding: 6px 12px;
  `,
  [EElementSize.M]: css`
    padding: 2px 8px;
  `,
};

export const SelectorTabItemStyled = styled.button<ISelectorTabItemStyledProps>`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray[50]};
  font-family: inherit;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;
  }

  ${({ $size }) => ItemSizeMap[$size]}

  ${({ $isSelected, theme }) => $isSelected && css`
    color: ${theme.colors.gray[0]};
    background-color: ${theme.colors.blue[50]};

    &:disabled {
      background-color: ${({ theme }) => theme.colors.gray[20]};
    }
  `}

  ${({ $isSelected, theme }) => !$isSelected && css`
    &:not(:disabled):hover {
      color: ${theme.colors.gray[70]};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.gray[30]};
    }
  `}
`;
