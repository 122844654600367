import styled, { keyframes } from 'styled-components';

export const speed = 1.5;

export const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const dash = keyframes`
  0% {
    stroke-dasharray: 0, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100, 150;
    stroke-dashoffset: -24;
  }
  100% {
    stroke-dasharray: 0, 150;
    stroke-dashoffset: -124;
  }
`;

interface SvgProps {
  $small?: boolean;
}

export const Svg = styled.svg<SvgProps>`
  animation: ${rotate} ${speed * 1.75}s linear infinite;
  height: ${p => (p.$small ? '1.25rem' : '3rem')};
  width: ${p => (p.$small ? '1.25rem' : '3rem')};
  transform-origin: center;
`;

export const Circle = styled.circle`
  animation: ${dash} ${speed}s ease-in-out infinite;
  stroke: #2f80ed;
  stroke-linecap: round;
`;

export const LoadingIndicatorAbsoluteContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
`;

export const LoadingIndicatorFullContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px;
  box-sizing: border-box;
`;
