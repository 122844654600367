import styled from 'styled-components';

export const DateRangeHeaderStyled = styled.div`
  display: flex;
  gap: 24px;
  position: relative;
  width: 584px;

  &:after {
    content: "-";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  & > * {
    display: grid;
    grid-gap: 4px;
    width: 50%;
    grid-template-columns: 160px auto;
    position: relative;

    z-index: 1;
  }
`;
