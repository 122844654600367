import { createContext, useState, FC, useMemo, useCallback } from 'react';
import { IDropDownProviderProps } from './drop-down.types';

interface IDropDownContext {
  closeDropdown: () => void;
  toggleContent: () => void;
  openDropdown: () => void;
  isOpen: boolean;
}

export const DropDownContext = createContext<IDropDownContext | undefined>(
  undefined,
);

export const DropDownProvider: FC<IDropDownProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleContent = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const closeDropdown = useCallback(() => setIsOpen(false), []);
  const openDropdown = useCallback(() => setIsOpen(true), []);

  const dropDownContextProviderValue = useMemo(
    () => ({ openDropdown, closeDropdown, toggleContent, isOpen }),
    [closeDropdown, isOpen, openDropdown, toggleContent],
  );

  return (
    <DropDownContext.Provider value={dropDownContextProviderValue}>
      {children}
    </DropDownContext.Provider>
  );
};
